const testData = [
    {
        id:1,
        number: 1,
        created_at: '01.01.2021',
        date:'01.01.2021',
        region: {id:36, label:'Воронежская обл.'},
        city: {id:36, label:'г.Воронеж'},
        creator: {id:1, label:'Пользователь'},
        type: {id:1, label:'Досудебное'}, // pre-trial, trial
        violator: {id: 1, label:'Иванов'},
        author: {id: 1, label:'Иванов'},
        dueDate: '01.02.2021',
        court: null,
        courtCaseNumber: null,
        claimCost:10,
        caseCost:20,
        paymentStatus: {id:1, label:'Не оплачен'},
        paymentType: null, //instant payment || installment payment
        paymentDate: null, // list of payments
        scanChange: null, // email, request, response
        originalChange: null, // address, sended, recived
        status: {id:1, label:'сформирована претензия'},
        tags: [],
        statusLine: [
            {
                id:1,
                title: 'сформирована претензия',
                dateTime: '10.01.2021',
                info: 'Претензия создана успешно'

            },
            {
                id:2,
                title: 'направлена претензия',
                dateTime: '21.01.2021',
                info: ''

            },
        ],
        violations: [2, 3]
    },
    {
        id:2,
        number: 2,
        created_at: '01.01.2021',
        date:'01.01.2021',
        region: {id:36, label:'Воронежская обл.'},
        city: {id:36, label:'г.Воронеж'},
        creator: {id:1, label:'Пользователь'},
        type: {id:2, label:'В суде'}, 
        violator: {id: 1, label:'Иванов'},
        author: {id: 1, label:'Иванов'},
        dueDate: '01.02.2021',
        court: null,
        courtCaseNumber: null,
        claimCost:30,
        caseCost:40,
        paymentStatus: {id:1, label:'Не оплачен'},
        paymentType: null, //instant payment || installment payment
        paymentDate: null, // list of payments
        scanChange: null, // email, request, response
        originalChange: null, // address, sended, recived
        status: {id:1, label:'подготовка иска'},
        tags: [],
        statusLine: [
            {
                id:1,
                title: 'сформирована претензия',
                dateTime: '10.01.2021',
                info: 'Претензия создана успешно'

            },
            {
                id:2,
                title: 'направлена претензия',
                dateTime: '21.01.2021',
                info: ''

            },
        ],
        violations: [5]
    },
]

const emptyItem = {
        id:null,
        number: null,
        created_at: null,
        date: new Date().toLocaleDateString(),
        region: '',
        city: null,
        creator: null,
        type: null, // pre-trial, trial
        violator: null,
        author: null,
        dueDate: null,
        court: null,
        courtCaseNumber: null,
        claimCost:null,
        caseCost:null,
        paymentStatus: {id:1, label:'Не оплачен'},
        paymentType: null, //instant payment || installment payment
        paymentDate: null, // list of payments
        scanChange: null, // email, request, response
        originalChange: null, // address, sended, recived
        status: {id:1, label:'сформирована претензия'},
        tags: [],
        statusLine: [
            {
                id:1,
                title: 'сформирована претензия',
                dateTime: '10.01.2021',
                info: 'Претензия создана успешно'

            }
        ],
        violations: []
    }

const state = {
    data : [],
    item: {},
    types: [{id:1, label:'Досудебное'}, {id:2, label:'В суде'}],
    stats:[],
    violations:[],
    violationsByViolator: [],
    violationsError:false,
    createByViolation: null
}

const getters = {
    
}
 
const actions = {
    fetchData({ commit }) {
        return new Promise((resolve) => {
               commit('SET_DATA', testData); 
               resolve();
        })
    },
    fetchItemById({ commit }, id) {
        return new Promise((resolve) => {
            let item = testData.filter(v => v.id == id)
               commit('SET_ITEM', item[0]); 
               resolve();
        })
    },
    async fetchViolations({ commit, dispatch }) {
        let data = await dispatch('violations/fetchDataByIds', state.item.violations, {root: true})
        return new Promise((resolve) => {
               commit('SET_VIOLATIONS', data); 
               resolve();
        })
    },
    async fetchViolationsByViolator({ commit, dispatch }) {
        let data = await dispatch('violations/fetchDataByViolator', state.item.violator.id, {root: true})
        return new Promise((resolve) => {
               commit('SET_VIOLATIONS_BY_VIOLATOR', data); 
               resolve();
        })
    },
    setInfo({ commit }, form) {
        return new Promise((resolve) => {
            commit('SET_INFO', form);
            resolve();
        })
    },
    setStatus({ commit }, form) {
        return new Promise((resolve) => {
            commit('SET_STATUS', form); 
            resolve();
        })
    },
    async fetchStatsByType({ commit, dispatch }, id) {
        let data = await dispatch('catalog_stats/fetchDataByType', id, {root: true})
        return new Promise((resolve) => {
               commit('SET_STATS', data); 
               resolve();
        })
    },
    create({commit, state, dispatch}, form) {
        return new Promise((resolve) => {
            commit('ADD_CASE', form); 
            dispatch('violations/setCase', {v:state.item.violations, c:state.item.id}, {root: true})
            commit('WIPE_ITEM')
            resolve();
     })
    }
}

const mutations = {
    SET_DATA(state, data) {
       state.data = data;
    },
    SET_EMPTY_ITEM(state) {
        // state.updateId = null
        state.item = {...emptyItem};
        if(state.createByViolation !== null){
            // console.log(state.createByViolation.author)
            state.item.author = state.createByViolation.author
            state.item.violator = state.createByViolation.violator
        }
        console.log(state.item)
        state.violations = []
    },
    SET_ITEM(state, data){
        state.item = data
    },
    SET_INFO(state, data){
        state.item.type = data.type
        state.item.date = data.date
        state.item.city = data.city
        state.item.region = data.region
    },
    SET_VIOLATOR(state, data){
        state.item.violator = data
    },
    SET_STATS(state, data){
        state.stats = data
    },
    SET_VIOLATIONS(state, data){
        if(data.length > 0){
            state.violationsError = false
        }
        state.violations = data
    },
    SET_VIOLATIONS_ERROR(state, data){
        state.violationsError = data
    },
    SET_VIOLATIONS_BY_VIOLATOR(state, data){
        state.violationsByViolator = data
    },
    SET_STATUS(state, data){
        state.item.statusLine.push({ 
            title: data.status.label,
            dateTime: '10.01.2021',
            info: data.info
        })
        state.item.status = data.status
    },
    SET_CREATE_ON_VIOLATION(state, data){
        state.createByViolation = data
    },
    ADD_CASE(state, form){
        let v=[]
        state.violations.forEach(val=>{
            v.push(val.id)
        })
        state.item.id = testData.length + 1
        state.item.number = testData.length + 1
        state.item.type = form.type
        state.item.date = form.date
        state.item.city = form.city
        state.item.region = form.region
        state.item.violator = form.violator
        state.item.author = form.author
        state.item.dueDate = '01.02.2021'
        state.item.caseCost = '-'
        state.item.claimCost = '-'
        state.item.violations = v
        testData.push(state.item)
        
    },
    WIPE_ITEM(state){
        state.item = {}
        state.violations = []
        state.createByViolation = null
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}