const testData = [
    {
    id:1,
    number: 1,
    date: '01.01.2021',
    author: {id:1, label:'Сидоров И.И.'},
    violator: {id:1, label:'ООО Нарушитель'},
    type: {id:1, label:'Онлайн'},
    case: null,
    status: {id:1, label:'Новый'},
    },
    {
    id:2,
    number: 2,
    date: '01.11.2020',
    author: {id:1, label:'Сидоров И.И.'},
    violator: {id:1, label:'ООО Нарушитель'},
    type: {id:1, label:'Онлайн'},
    case: null,
    status: {id:1, label:'Новый'},
    },
    {
    id:3,
    number: 3,
    date: '01.10.2020',
    author: {id:1, label:'Сидоров И.И.'},
    violator: {id:1, label:'ООО Нарушитель'},
    type: {id:2, label:'Оффлайн'},
    case: {id:1, number: 1},
    status: {id:2, label:'В работе'},
    },
    {
    id:4,
    number: 4,
    date: '10.11.2020',
    author: {id:1, label:'Сидоров И.И.'},
    violator: null,
    type: {id:2, label:'Оффлайн'},
    case: {id:2, number: 2},
    status: {id:2, label:'В работе'},
    },
    {
    id:5,
    number: 5,
    date: '10.10.2020',
    author: {id:2, label:'Петров И.И.'},
    violator: {id:1, label:'ООО Нарушитель'},
    type: {id:2, label:'Оффлайн'},
    case: {id:1, number: 1},
    status: {id:3, label:'Архивный'},
    },
]

const testObjects = [
    { 
        violationId: 1,
        objects: [
            {
                pageUrl: 'http://1d3.ru',
                imageUrl:null,
                contactsUrl:null,
                photoBlogUrl: null,
                photoBlogImageUrl:null,
                blogYear:null,
                domain:null,
                type: {id:1, label:'фотоизображение'}
            },
            {
                pageUrl: 'http://1ya.ru',
                imageUrl:null,
                contactsUrl:null,
                photoBlogUrl: null,
                photoBlogImageUrl:null,
                blogYear:null,
                domain:null,
                type: {id:1, label:'фотоизображение'}
            }
        ]
    },
    { 
        violationId: 2,
        objects: [
            {
                pageUrl: 'http://2d3.ru',
                imageUrl:null,
                contactsUrl:null,
                photoBlogUrl: null,
                photoBlogImageUrl:null,
                blogYear:null,
                domain:null,
                type: {id:1, label:'фотоизображение'}
            },
            {
                pageUrl: 'http://2ya.ru',
                imageUrl:null,
                contactsUrl:null,
                photoBlogUrl: null,
                photoBlogImageUrl:null,
                blogYear:null,
                domain:null,
                type: {id:1, label:'фотоизображение'}
            }
        ]
    }
]

const state = {
    data:[],
    objects:[],
    item: {},
    types: [
        {id:1, value:'Онлайн'},
        {id:2, value:'Оффлайн'}
    ],
    stats: [
        {id:1, value:'Новый'},
        {id:2, value:'В работе'},
        {id:3, value:'Архивный'}
    ]
}

const getters = {
    data( state ) {
       return state.data;
    },
}
 
const actions = {
    fetchObjects({ commit }, violationId) {
        return new Promise((resolve) => {
            let filtred = testObjects.filter(val => val.violationId == violationId)
            if(filtred.length > 0){
                commit('SET_OBJECTS', filtred[0].objects); 
            }
            resolve();
        })
    },
    fetchData({ commit }) {
        return new Promise((resolve) => {
               commit('SET_DATA', testData); 
               resolve();
        })
    },
    fetchDataByIds({state},ids) {
        console.log(state)
        return new Promise((resolve) => {
            let data = []
            testData.forEach(item => {
                if (ids.includes(item.id)){
                    data.push(item)
                }
            })
               resolve(data);
        })
    },
    fetchDataByViolator({state},id) {
        console.log(state)
        return new Promise((resolve) => {
            let data = []
            testData.forEach(item => {
                if (item.violator !== null && item.case === null && item.violator.id === id){
                    data.push(item)
                }
            })
            resolve(data);
        })
    },
    fetchItemById({ commit }, id) {
        return new Promise((resolve) => {
            let item = testData.filter(v => v.id == id)
               commit('SET_ITEM', item[0]); 
               resolve();
        })
    },
    setCase({ commit }, ids) {
        commit('SET_CASE', ids);
    }
}

const mutations = {
    SET_DATA(state, data) {
       state.data = data;
    },
    SET_STATUS(state, data) {
        state.data[data.row].status = data.status;
    },
    //violations
    ADD_VIOLATION(state, data) {
        const num = testData.length + 2
        testData.push({
            id: num,
            number: num,
            date: new Date().toLocaleDateString(),
            author: data.author,
            violator: data.violator,
            type: data.type,
            case: null,
            status: {id:1, label:'Новый'},
            })
        testObjects.push({
            violationId: num,
            objects: state.objects
        })
    },
    SET_ITEM(state, data){
        state.item = data
    },
    // objects
    EMPTY_OBJECTS(state) {
        state.objects = []
    },
    SET_OBJECTS(state, data) {
        state.objects = data
    },
    ADD_OBJECT(state, data) {
        state.objects.push(data.object)
    },
    UPDATE_OBJECT(state, data){
        state.objects[data.index] = data.object;
    },
    DELETE_OBJECT(state, data) {
        state.objects.splice(data.objectId,1)
    },
    SET_CASE(state, ids){
        console.log(state)
        testData.forEach(item=>{
            if (ids.v.indexOf(item.id) !== -1){
                item.case = {id: ids.c, number:ids.c}
            }
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}