
const testData = [
    {
        id:1,
        type:{id:1, label:'Досудебное'},
        name: 'сформирована претензия',
        status:{id:1, label:'Активен'},
        info: ''
    },
    {
        id:2,
        type:{id:1, label:'Досудебное'},
        name: 'направлена претензия',
        status:{id:1, label:'Активен'},
        info: ''
    },
    {
        id:3,
        type:{id:1, label:'Досудебное'},
        name: 'переговоры',
        status:{id:1, label:'Активен'},
        info: ''
    },
    {
        id:4,
        type:{id:1, label:'Досудебное'},
        name: 'заключение соглашения',
        status:{id:1, label:'Активен'},
        info: ''
    },
    {
        id:5,
        type:{id:1, label:'Досудебное'},
        name: 'ожидает оплаты',
        status:{id:1, label:'Активен'},
        info: ''
    },
    {
        id:6,
        type:{id:1, label:'Досудебное'},
        name: 'урегулировано досудебно',
        status:{id:1, label:'Активен'},
        info: ''
    },
    {
        id:7,
        type:{id:1, label:'Досудебное'},
        name: 'отказ от требований',
        status:{id:1, label:'Активен'},
        info: ''
    },
    {
        id:8,
        type:{id:2, label:'В суде'}, 
        name:'подготовка иска',
        status:{id:1, label:'Активен'},
        info: ''
    },
    {
        id:9,
        type:{id:2, label:'В суде'}, 
        name:'сформирован иск',
        status:{id:1, label:'Активен'},
        info: ''
    },
    {
        id:10,
        type:{id:2, label:'В суде'},
        name:'подан иск',
        status:{id:1, label:'Активен'},
        info: ''
    },
    {
        id:11,
        type:{id:2, label:'В суде'},
        name:'иск принят к рассмотрению',
        status:{id:1, label:'Активен'},
        info: ''
    },
    {
        id:12,
        type:{id:2, label:'В суде'},
        name:'без движения',
        status:{id:1, label:'Активен'},
        info: ''
    },
    {
        id:13,
        type:{id:2, label:'В суде'},
        name:'вынесено решение',
        status:{id:1, label:'Активен'},
        info: ''
    },
    {
        id:14,
        type:{id:2, label:'В суде'},
        name:'заключение мирового соглашения',
        status:{id:1, label:'Активен'},
        info: ''
    },
    {
        id:15,
        type:{id:2, label:'В суде'},
        name:'ожидает оплаты',
        status:{id:1, label:'Активен'},
        info: ''
    },
    {
        id:16,
        type:{id:2, label:'В суде'},
        name:'исполнительное производство',
        status:{id:1, label:'Активен'},
        info: ''
    },
    {
        id:17,
        type:{id:2, label:'В суде'},
        name:'исполнено',
        status:{id:1, label:'Активен'},
        info: ''
    },
    {
        id:18,
        type:{id:2, label:'В суде'},
        name:'отказ от иска',
        status:{id:1, label:'Активен'},
        info: ''
    }
] 


const emptyItem = {
    id: null,
    type:null,
    name: null,
    status:null,
    info:null,
}

const state = {
    data : [],
    item: null,
    updateId: null,
    options: {
        types: [
            {id:1, label:'Досудебное'}, 
            {id:2, label:'В суде'}
        ],
        stats: [
            {id:1, label:'Активно'},
            {id:2, label:'Не активно'},
        ]
    }
}

const getters = {
    data( state ) {
       return state.data;
    },
}
 
const actions = {
    fetchData({ commit }) {
        return new Promise((resolve) => {
            // Make network request and fetch data
            // and commit the data
            commit('SET_DATA', testData); 
            resolve();
        })
    },
    fetchItemById({ commit }, id) {
        return new Promise((resolve) => {
            // Make network request and fetch data
            // and commit the data
            let data = testData.filter(v => v.id === id)
            if(data.length > 0){
                commit('SET_ITEM', {id:id, data:data[0]}); 
            }
            resolve();
        })
    },
    fetchDataByType({state},id) {
        console.log(state)
        return new Promise((resolve) => {
            let data = testData.filter(v => v.type.id == id).map(val => {return {id:val.id, label:val.name}})
               resolve(data);
        })
    },
    addItem({ commit }, data) {
        return new Promise((resolve) => {
            commit('ADD_ITEM', data);    
            resolve();
        })
    },
    updateItem({ commit }, payload) {
        return new Promise((resolve) => {
            commit('UPDATE_ITEM', {id:payload.id, data:payload.item});    
            resolve();
        })
    },
    deleteItem({ commit }, id) {
        return new Promise((resolve) => {
            commit('DELETE_ITEM', id);    
            resolve();
        })
    }
}

const mutations = {
    SET_DATA(state, payload) {
        state.data = payload;
     },
    SET_ITEM(state, payload) {
        state.updateId = payload.id
        state.item = {...payload.data};
    },
    SET_EMPTY_ITEM(state) {
        state.updateId = null
        state.item = {...emptyItem};
    },
    ADD_ITEM(state, payload){
        payload.id = state.data.length + 1
        state.data.push(payload)
    },
    UPDATE_ITEM(state, payload){
        const index = state.data.findIndex(item => item.id === payload.id);
        if (index !== -1) state.data.splice(index, 1, payload.data);
    },
    DELETE_ITEM(state, id){
        const index = state.data.findIndex(item => item.id === id);
        if(index !== -1) state.data.splice(index, 1)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}