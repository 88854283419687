
const testData = [
    {
        id:1, 
        name: 'Param',
        param: 'param',
        value: 'value',
        status: {id:1, label:'Активно'},
        info: ''
    },
    {
        id:2, 
        name: 'Param1',
        param: 'param1',
        value: 'value1',
        status: {id:1, label:'Активно'},
        info: ''
    },
  
] 

const emptyItem = {
    id: null,
    name: null,
    param: null,
    value: null,
    status: null,
    info: null,
}

const state = {
    data : [],
    item: null,
    updateId: null,
    options: {
        stats: [
            {id:1, label:'Активно'},
            {id:2, label:'Не активно'},
        ]
    }
}

const getters = {
    data( state ) {
       return state.data;
    },
}
 
const actions = {
    fetchData({ commit }) {
        return new Promise((resolve) => {
            // Make network request and fetch data
            // and commit the data
            commit('SET_DATA', testData); 
            resolve();
        })
    },
    fetchItemById({ commit }, id) {
        return new Promise((resolve) => {
            // Make network request and fetch data
            // and commit the data
            let data = testData.filter(v => v.id === id)
            if(data.length > 0){
                commit('SET_ITEM', {id:id, data:data[0]}); 
            }
            resolve();
        })
    },
    addItem({ commit }, data) {
        return new Promise((resolve) => {
            commit('ADD_ITEM', data);    
            resolve();
        })
    },
    updateItem({ commit }, payload) {
        return new Promise((resolve) => {
            commit('UPDATE_ITEM', {id:payload.id, data:payload.item});    
            resolve();
        })
    },
    deleteItem({ commit }, id) {
        return new Promise((resolve) => {
            commit('DELETE_ITEM', id);    
            resolve();
        })
    }
}

const mutations = {
    SET_DATA(state, payload) {
        state.data = payload;
     },
    SET_ITEM(state, payload) {
        state.updateId = payload.id
        state.item = {...payload.data};
    },
    SET_EMPTY_ITEM(state) {
        state.updateId = null
        state.item = {...emptyItem};
    },
    ADD_ITEM(state, payload){
        payload.id = state.data.length + 1
        state.data.push(payload)
    },
    UPDATE_ITEM(state, payload){
        const index = state.data.findIndex(item => item.id === payload.id);
        if (index !== -1) state.data.splice(index, 1, payload.data);
    },
    DELETE_ITEM(state, id){
        const index = state.data.findIndex(item => item.id === id);
        if(index !== -1) state.data.splice(index, 1)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}