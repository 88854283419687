import Vue from 'vue'
import Vuex from 'vuex' 

import violations from './modules/violations';
import cases from './modules/cases';
import authors from './modules/authors';
import copyrightHolders from './modules/copyrightHolders';
import violators from './modules/violators';
import regions from './modules/regions';
import tpl_attributes from './modules/tpl_attributes';
import tpl_blocks from './modules/tpl_blocks';
import tpl_documents from './modules/tpl_documents';
import catalog_domain_regs from './modules/catalog_domain_regs';
import catalog_stats from './modules/catalog_stats';
import catalog_documents from './modules/catalog_documents';
import config_users from './modules/config_users';
import config_settings from './modules/config_settings';
import router from '../router'

Vue.use(Vuex)

const state = {
    leftDrawerOpen: false,
    loader: true,
    token: localStorage.getItem('t') || null
}


const getters = {
    loader(state) {
        return state.loader
    },
    isAuthenticated(state) {
        return state.token != null
    },
    token(state) {
        return state.token
    },
    tokenData (state) {
        return state.token || null//state.token ? JSON.parse(atob(state.token.split('.')[1])) : null
    },
    tokenUid(state){
        return state.token ||null//getters.tokenData ? getters.tokenData.uid : null
    },
}

const mutations = {
    LEFT_DROWER_CHANGE(state) {
        if(state.leftDrawerOpen === false){
            state.leftDrawerOpen = true;
        }else{
            state.leftDrawerOpen = false;
        }
    },
    SET_LOADER(state, action) {
        state.loader = action
    },
    SET_TOKEN (state, token) {
        localStorage.setItem('t', token)
        // state.token = token
    },
    REMOVE_TOKEN(state) {
        state.token = null
        localStorage.removeItem('t')
    }
}

const actions = {
    login({ commit, state }, data) {
        return new Promise((resolve, reject) => {
            if(state.token){
                router.push('/')
            }else{
                setTimeout(() => {
                    if(data.username == 'adm136' & data.password == 'pass234'){
                        commit('SET_TOKEN', true);
                        resolve(true)
                        return
                    }else{
                        reject(false)
                    }
                    
                }, 2000)
            }
        })
    },
}

export default new Vuex.Store({
    state,
    getters,
    mutations,
    actions,
    modules: {
        config_users,
        config_settings,
        violations,
        cases,
        authors,
        copyrightHolders,
        violators,
        regions,
        tpl_attributes,
        tpl_blocks,
        tpl_documents,
        catalog_domain_regs,
        catalog_stats,
        catalog_documents
     }
})
  