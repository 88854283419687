const testData = [
    {
        id:1, 
        name: 'ООО Нарушитель',
        inn: '123123123',
        ogrn: '123123123',
        address: 'г.Воронеж ул. Карла Маркса д43',
        phone: '123123123',
        email: 'qwe@qwe.we',
        info: '',
        admin:{
            name: 'Петров',
            inn: '123123123',
            ogrn: '123123123',
            address: 'г.Воронеж ул. Карла Маркса д43',
            phone: '123123123',
            email: 'qwe@qwe.we',
            info: '',
        },
        isSameDataForAdmin:false
    },
    {
        id:2, 
        name: 'Петров',
        inn: '123123123',
        ogrn: '123123123',
        address: 'г.Воронеж ул. Карла Маркса д43',
        phone: '123123123',
        email: 'qwe@qwe.we',
        info: '',
        admin:{
            name: 'Петров',
            inn: '123123123',
            ogrn: '123123123',
            address: 'г.Воронеж ул. Карла Маркса д43',
            phone: '123123123',
            email: 'qwe@qwe.we',
            info: '',
        },
        isSameDataForAdmin:false
    },
   
] 

const emptyItem = {
    name: null,
    inn: null,
    ogrn: null,
    address: null,
    phone: null,
    email: null,
    info: null,
    admin:{
        name: null,
        inn: null,
        ogrn: null,
        address: null,
        phone: null,
        email: null,
        info: null,
    },
    isSameDataForAdmin:false
}

const state = {
    data : [],
    item: null,
    updateId: null
}

const getters = {
    data( state ) {
       return state.data;
    },
}
 
const actions = {
    fetchData({ commit }) {
        return new Promise((resolve) => {
            // Make network request and fetch data
            // and commit the data
            commit('SET_DATA', testData); 
            resolve();
        })
    },
    fetchItemById({ commit }, id) {
        return new Promise((resolve) => {
            // Make network request and fetch data
            // and commit the data
            let data = testData.filter(v => v.id === id)
            if(data.length > 0){
                commit('SET_ITEM', {id:id, data:data[0]}); 
            }
            resolve();
        })
    },
    searchByName({state},needle){
        console.log(state)
        return new Promise((resolve) => {
            let data = testData.filter(v => v.name.toLowerCase().indexOf(needle) > -1).map(v=>{return {id:v.id, label:v.name}})
            resolve(data);
        })
    },
    addItem({ commit }, data) {
        return new Promise((resolve) => {
            commit('ADD_ITEM', data);    
            resolve();
        })
    },
    updateItem({ commit }, payload) {
        return new Promise((resolve) => {
            commit('UPDATE_ITEM', {id:payload.id, data:payload.item});    
            resolve();
        })
    },
    deleteItem({ commit }, id) {
        return new Promise((resolve) => {
            commit('DELETE_ITEM', id);    
            resolve();
        })
    }
}

const mutations = {
    SET_DATA(state, payload) {
        state.data = payload;
     },
    SET_ITEM(state, payload) {
        state.updateId = payload.id
        state.item = {...payload.data};
    },
    SET_EMPTY_ITEM(state) {
        state.updateId = null
        state.item = {...emptyItem};
    },
    ADD_ITEM(state, payload){
        payload.id = state.data.length + 1
        state.data.push(payload)
    },
    UPDATE_ITEM(state, payload){
        const index = state.data.findIndex(item => item.id === payload.id);
        if (index !== -1) state.data.splice(index, 1, payload.data);
    },
    DELETE_ITEM(state, id){
        const index = state.data.findIndex(item => item.id === id);
        if(index !== -1) state.data.splice(index, 1)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}