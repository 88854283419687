import store from '../store'

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
  }else{
    next('/login')
  }
}

const routes = [
    {
      path: '/',
      component: () => import('@/layouts/main.vue'),
      children: [
        {
          path: '/', 
          component: () => import('@/pages/default.vue'), 
          meta:{
            bc:[
              {name:'Главная', link:''}
            ]
          }
        },
        {
          path: '/cases', 
          component: () => import('@/pages/cases/index.vue'),
          meta:{
            title: 'Нарушения', 
            bc:[
              {name:'Главная', link:'/'},
              {name:'Дела', link:''}
            ]
          }
        },
        {
          path: '/cases/create', 
          component: () => import('@/pages/cases/create.vue'),
          meta:{
            title: 'Создать нарушение', 
            bc:[
              {name:'Главная', link:'/'},
              {name:'Дела', link:'/cases'},
              {name: 'Создать', link:''},
            ]
          }
        },
        {
          path: '/cases/:id', 
          component: () => import('@/pages/cases/update.vue'),
          meta:{
            title: 'Изменить нарушение', 
            bc:[
              {name:'Главная', link:'/'},
              {name:'Дела', link:'/cases'},
              {name: 'Изменить', link:''},
            ]
          }
        },
        {
          path: '/cases/:id/:tab', 
          component: () => import('@/pages/cases/update.vue'),
          meta:{
            title: 'Изменить нарушение', 
            bc:[
              {name:'Главная', link:'/'},
              {name:'Дела', link:'/cases'},
              {name: 'Изменить', link:''},
            ]
          }
        },
        {
          path: '/violations', 
          component: () => import('@/pages/violations/index.vue'),
          meta:{
            title: 'Нарушения', 
            bc:[
              {name:'Главная', link:'/'},
              {name:'Нарушения', link:''}
            ]
          }
        },
        {
          path: '/violations/create', 
          component: () => import('@/pages/violations/crud.vue'),
          meta:{
            title: 'Создать нарушение', 
            bc:[
              {name:'Главная', link:'/'},
              {name:'Нарушения', link:'/violations'},
              {name: 'Создать', link:''},
            ]
          }
        },
        {
          path: '/violations/:id', 
          component: () => import('@/pages/violations/crud.vue'),
          meta:{
            title: 'Изменить нарушение', 
            bc:[
              {name:'Главная', link:'/'},
              {name:'Нарушения', link:'/violations'},
              {name: 'Изменить', link:''},
            ]
          }
        },
        {
          path: '/authors', 
          component: () => import('@/pages/authors/index.vue'),
          meta:{
            title: 'Авторы', 
            bc:[
              {name:'Главная', link:'/'},
              {name:'Авторы', link:'' },
            ]
          }
        },
        {
          path: '/violators', 
          component: () => import('@/pages/violators/index.vue'),
          meta:{
            title: 'Нарушители', 
            bc:[
              {name:'Главная', link:'/'},
              {name:'Нарушители', link:''},
            ]
          }
        },
        {
          path: '/claims', 
          component: () => import('@/pages/claims/index.vue'),
          meta:{
            title: 'Притензии', 
            bc:[
              {name:'Главная', link:'/'},
              {name:'Притензии', link:''},
            ]
          }
        },
        {
          path: '/copyright-holders', 
          component: () => import('@/pages/copyright_holders/index.vue'),
          meta:{
            title: 'Правообладатели', 
            bc:[
              {name:'Главная', link:'/'},
              {name:'Правообладатели', link:''},
            ]
          }
        },
        {
          path: '/tpl-attributes', 
          component: () => import('@/pages/templates/attributes.vue'),
          meta:{
            title: 'Шаблоны - Маркеры', 
            bc:[
              {name:'Главная', link:'/'},
              {name:'Шаблоны - Маркеры', link:''},
            ]
          }
        },
        {
          path: '/tpl-blocks', 
          component: () => import('@/pages/templates/blocks/index.vue'),
          meta:{
            title: 'Шаблоны - Блоки', 
            bc:[
              {name:'Главная', link:'/'},
              {name:'Шаблоны - Блоки', link:''},
            ]
          }
        },
        {
          path: '/tpl-blocks/create', 
          component: () => import('@/pages/templates/blocks/crud.vue'),
          meta:{
            title: 'Шаблоны - Блоки', 
            bc:[
              {name:'Главная', link:'/'},
              {name:'Шаблоны - Блоки', link:'/tpl-blocks'},
              {name:'Создать', link:''},
            ]
          }
        },
        {
          path: '/tpl-blocks/:id', 
          component: () => import('@/pages/templates/blocks/crud.vue'),
          meta:{
            title: 'Шаблоны - Блоки', 
            bc:[
              {name:'Главная', link:'/'},
              {name:'Шаблоны - Блоки', link:'/tpl-blocks'},
              {name:'Изменить', link:''},
            ]
          }
        },
        {
          path: '/tpl-notify', 
          component: () => import('@/pages/templates/notify.vue'),
          meta:{
            title: 'Уведомления', 
            bc:[
              {name:'Главная', link:'/'},
              {name:'Уведомления', link:''},
            ]
          }
        },
        {
          path: '/tpl-documents', 
          component: () => import('@/pages/templates/documents/index.vue'),
          meta:{
            title: 'Шаблоны - Документы', 
            bc:[
              {name:'Главная', link:'/'},
              {name:'Шаблоны - Документы', link:''},
            ]
          }
        },
        {
          path: '/tpl-documents/create', 
          component: () => import('@/pages/templates/documents/crud.vue'),
          meta:{
            title: 'Шаблоны - Документы', 
            bc:[
              {name:'Главная', link:'/'},
              {name:'Шаблоны - Документы', link:'/tpl-documents'},
              {name:'Создать', link:''},
            ]
          }
        },
        {
          path: '/tpl-documents/:id', 
          component: () => import('@/pages/templates/documents/crud.vue'),
          meta:{
            title: 'Шаблоны - Документы', 
            bc:[
              {name:'Главная', link:'/'},
              {name:'Шаблоны - Документы', link:'/tpl-documents'},
              {name:'Изменить', link:''},
            ]
          }
        },
        {
          path: '/dir-domain-regs', 
          component: () => import('@/pages/catalog/domain_regs.vue'),
          meta:{
            title: 'Справочники - Регистраторы доменов', 
            bc:[
              {name:'Главная', link:'/'},
              {name:'Справочники - Регистраторы доменов', link:''},
            ]
          }
        },
        {
          path: '/dir-check-types', 
          component: () => import('@/pages/catalog/check_types.vue'),
          meta:{
            title: 'Справочники - Типы проверок', 
            bc:[
              {name:'Главная', link:'/'},
              {name:'Справочники - Типы проверок', link:''},
            ]
          }
        },
        {
          path: '/dir-stats', 
          component: () => import('@/pages/catalog/case_stats.vue'),
          meta:{
            title: 'Справочники - Статусы', 
            bc:[
              {name:'Главная', link:'/'},
              {name:'Справочники - Статусы', link:''},
            ]
          }
        },
        {
          path: '/dir-documents', 
          component: () => import('@/pages/catalog/documents/index.vue'),
          meta:{
            title: 'Справочники - Документы', 
            bc:[
              {name:'Главная', link:'/'},
              {name:'Справочники - Документы', link:''},
            ]
          }
        },
        {
          path: '/dir-documents/:id', 
          component: () => import('@/pages/catalog/documents/update.vue'),
          meta:{
            title: 'Справочники - Документы', 
            bc:[
              {name:'Главная', link:'/'},
              {name:'Справочники - Документы', link:'/dir-documents'},
              {name:'Изменить', link:''},
            ]
          }
        },
        {
          path: '/config-users', 
          component: () => import('@/pages/config/users.vue'),
          meta:{
            title: 'Настройки - Пользователи', 
            bc:[
              {name:'Главная', link:'/'},
              {name:'Настройки - Пользователи'}
            ]
          }
        },
        {
          path: '/config-settings', 
          component: () => import('@/pages/config/settings.vue'),
          meta:{
            title: 'Настройки - Параметры', 
            bc:[
              {name:'Главная', link:'/'},
              {name:'Настройки - Параметры'}
            ]
          }
        },
        {
          path: '/profile', 
          component: () => import('@/pages/profile.vue'),
          meta:{
            title: 'Профиль', 
            bc:[
              {name:'Главная', link:'/'},
              {name:'Профиль'}
            ]
          }
        },
      ], 
      beforeEnter:ifAuthenticated,
    },
    {
        path: '/login',
        component: () => import('@/pages/auth/login.vue'),
        meta: {title: 'Верни моё - Вход'}
    },
    {
      path: '*',
      component: () => import('@/pages/404.vue'),
      beforeEnter: ifAuthenticated,
    }
]

export default routes