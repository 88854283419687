
const testData = [
    {
        id:1, 
        name: 'Документ',
        text: 'ПРЕТЕНЗИЯ №{{claim_num}} <br>На администрируемом (используемом) вами сайте с доменным именем {{domain}} используются фотографии, автором которых является - {{author_name}}. \
        {{blog_url_remove}}Фото впервые было опубликовано автором в своем блоге (сайте) {{blog_url}} .\
        Список интернет-страниц, где было допущено нарушение:\
        {{violation_url}}\
        Исключительные права на указанные изображения, находятся в управлении ФАПФ "Пейзаж" на основании договора доверительного управления №{{agreement_num}} от {{agreement_date}}, скан-копия которого доступна по ссылке: {{agreement_URL}} и приложений к нему, доступных по ссылкам:\
        {{agreement_appendix_URL}}\
        Ни управляющая организация, ни {{author_name}} не давали вам своего разрешения на опубликование, воспроизведение, переработку либо использование фотоизображений иным способом. Следовательно, использование вами фотоизображений, исключительные права на которые находятся в управлении ФАПФ "Пейзаж", является незаконным.\
        Указанное нарушение зафиксировано сервисом автоматической фиксации доказательств “ВЕБДЖАСТИС”, протокол от {{proto_date}} № {{proto_num}}, доступный по следующей ссылке:\
        {{proto_URL}}\
        В силу ч.1 ст. 1229 ГК РФ, другие лица не могут использовать соответствующие результат интеллектуальной деятельности без согласия правообладателя. Использование результата интеллектуальной деятельности, если такое использование осуществляется без согласия правообладателя, является незаконным и влечет установленную ответственность.\
        В соответствии с п.2 ст.1300 ГК РФ в отношении произведений не допускается удаление или изменение информации об авторском праве, а равно использование произведений в отношении которых такая информация была удалена или изменена.\
        Статьей 1301 ГК РФ установлена ответственность за нарушение исключительного права правообладателя в размере от 10 000 р. до 5 000 000 р., либо в размере 2-х кратной стоимости права.\
        Административный штраф, установленный ст. 7.12 КоАП РФ, составляет от 10000 до 20000 - на должностных лиц, на юридических лиц - от 30000 до 40000 рублей \
        ',
        type: {id:1, label:'Претензия'},
        status: {id:1, label:'Активно'},
        info: ''
    },
    {
        id:2, 
        name: 'Документ1',
        text: 'text {{МАРКЕР1}} {{БЛОК1}}',
        type: {id:2, label:'Исковое заявление'},
        status: {id:1, label:'Активно'},
        info: ''
    },
  
] 

const emptyItem = {
    id: null,
    name: null,
    text: null,
    type: null,
    status: null,
    info: null,
}

const state = {
    data : [],
    item: null,
    updateId: null,
    options: {
        types:[
            {id:1, label:'Претензия'},
            {id:2, label:'Исковое заявление'},
        ],
        stats: [
            {id:1, label:'Активно'},
            {id:2, label:'Не активно'},
        ]
    }
}

const getters = {
    data( state ) {
       return state.data;
    },
}
 
const actions = {
    fetchData({ commit }) {
        return new Promise((resolve) => {
            // Make network request and fetch data
            // and commit the data
            commit('SET_DATA', testData); 
            resolve();
        })
    },
    fetchItemById({ commit }, id) {
        return new Promise((resolve) => {
            // Make network request and fetch data
            // and commit the data
            let data = testData.filter(v => v.id == id)
            if(data.length > 0){
                commit('SET_ITEM', {id:id, data:data[0]}); 
            }
            resolve();
        })
    },
    addItem({ commit }, data) {
        return new Promise((resolve) => {
            commit('ADD_ITEM', data);    
            resolve();
        })
    },
    updateItem({ commit }, payload) {
        return new Promise((resolve) => {
            commit('UPDATE_ITEM', {id:payload.id, data:payload.item});    
            resolve();
        })
    },
    deleteItem({ commit }, id) {
        return new Promise((resolve) => {
            commit('DELETE_ITEM', id);    
            resolve();
        })
    }
}

const mutations = {
    SET_DATA(state, payload) {
        state.data = payload;
     },
    SET_ITEM(state, payload) {
        state.updateId = payload.id
        state.item = {...payload.data};
    },
    SET_EMPTY_ITEM(state) {
        state.updateId = null
        state.item = {...emptyItem};
    },
    ADD_ITEM(state, payload){
        payload.id = state.data.length + 1
        state.data.push(payload)
    },
    UPDATE_ITEM(state, payload){
        const index = state.data.findIndex(item => item.id == payload.id);
        if (index !== -1) state.data.splice(index, 1, payload.data);
    },
    DELETE_ITEM(state, id){
        const index = state.data.findIndex(item => item.id == id);
        if(index !== -1) state.data.splice(index, 1)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}