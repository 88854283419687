
const testData = [
    {
        id:1,
        key:'R01',
        name: 'ООО «Регистратор Р01»',
        address: '123308, г. Москва, а/я 99, ООО «Регистратор Р01»',
        phone: '8-800-775-37-83',
        email: 'info@r01.ru',
        status:{id:1, label:'Активен'},
        info: ''
    },
    {
        id:2,
        key:'RU-CENTER',
        name: 'АО «Региональный Сетевой Информационный Центр» ',
        address: '123308, РФ, г. Москва, ул. 3-я Хорошевская, д.2, стр.1',
        phone: '+7 495 737-06-17',
        email: 'ru-ncc@nic.ru',
        status:{id:1, label:'Активен'},
        info: ''
    },
   
] 

const emptyItem = {
    id: null,
    key:null,
    name: null,
    address:null,
    phone:null,
    email: null,
    status:null,
    info:null,
}

const state = {
    data : [],
    item: null,
    updateId: null,
    options: {
        stats: [
            {id:1, label:'Активно'},
            {id:2, label:'Не активно'},
        ]
    }
}

const getters = {
    data( state ) {
       return state.data;
    },
}
 
const actions = {
    fetchData({ commit }) {
        return new Promise((resolve) => {
            // Make network request and fetch data
            // and commit the data
            commit('SET_DATA', testData); 
            resolve();
        })
    },
    fetchItemById({ commit }, id) {
        return new Promise((resolve) => {
            // Make network request and fetch data
            // and commit the data
            let data = testData.filter(v => v.id === id)
            if(data.length > 0){
                commit('SET_ITEM', {id:id, data:data[0]}); 
            }
            resolve();
        })
    },
    addItem({ commit }, data) {
        return new Promise((resolve) => {
            commit('ADD_ITEM', data);    
            resolve();
        })
    },
    updateItem({ commit }, payload) {
        return new Promise((resolve) => {
            commit('UPDATE_ITEM', {id:payload.id, data:payload.item});    
            resolve();
        })
    },
    deleteItem({ commit }, id) {
        return new Promise((resolve) => {
            commit('DELETE_ITEM', id);    
            resolve();
        })
    }
}

const mutations = {
    SET_DATA(state, payload) {
        state.data = payload;
     },
    SET_ITEM(state, payload) {
        state.updateId = payload.id
        state.item = {...payload.data};
    },
    SET_EMPTY_ITEM(state) {
        state.updateId = null
        state.item = {...emptyItem};
    },
    ADD_ITEM(state, payload){
        payload.id = state.data.length + 1
        state.data.push(payload)
    },
    UPDATE_ITEM(state, payload){
        const index = state.data.findIndex(item => item.id === payload.id);
        if (index !== -1) state.data.splice(index, 1, payload.data);
    },
    DELETE_ITEM(state, id){
        const index = state.data.findIndex(item => item.id === id);
        if(index !== -1) state.data.splice(index, 1)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}