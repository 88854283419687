
const testData = [
    {
        id:36, 
        label:'Воронежская обл.',
        citys:[
            {id:36, label:'г.Воронеж'},
        ],
    },
    {   id:77,  
        label:'Московская обл.',
        citys:[
            {id:77, label:'г.Химки'},
        ]
    },
    {   id:78, 
        label:'г. Москва',
        citys:[
            {id:78, label:'г. Москва'}
        ]
    }
] 


const state = {
    data : [],
}

const getters = {
    list( state ) {
       return state.data.map(val=>{return {id:val.id, label:val.label}});
    },
    citys: (state) => (id) => {
        return state.data.filter(val=> val.id == id)[0].citys;
     },
}
 
const actions = {
    fetchRegions({ commit }) {
        return new Promise((resolve) => {
            // Make network request and fetch data
            // and commit the data
            commit('SET_DATA', testData); 
            resolve();
        })
    },
    // fetchCitys({ commit }, id) {
    //     return new Promise((resolve) => {
    //         // Make network request and fetch data
    //         // and commit the data
            
    //         let data = testCitys.filter(v => v.id === id)
    //         if(data.length > 0){
    //             commit('SET_CITYS',data); 
    //         }
    //         resolve();
    //     })
    // }
}

const mutations = {
    SET_DATA(state, payload) {
        state.data = payload;
     },
    // SET_CITYS(state, payload) {
    //     state.citys = payload
    // },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}